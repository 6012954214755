import { useTranslation } from "react-i18next";
import handleReportError from "../../errors/helpers/handleReportError";

const httpStatusCodes = {
    MAINTENANCE: 503,
    THROTTLE: 429,
    VALIDATION: 422,
};

export const errorsTypes = {
    INTERNAL_SERVER_ERROR: "internalServerError",
    MAINTENANCE: "maintenance",
    THROTTLE: "throttled",
};

export function mapErrorByHttpStatusCode(errorWithResponse, customErrors = {}) {
    if (!errorWithResponse.response || !errorWithResponse.response.status) {
        return { [errorsTypes.INTERNAL_SERVER_ERROR]: true };
    }

    switch (errorWithResponse.response.status) {
        case httpStatusCodes.VALIDATION:
            return errorWithResponse.response.data.errors;
        case httpStatusCodes.MAINTENANCE:
            return { [errorsTypes.MAINTENANCE]: true };
        case httpStatusCodes.THROTTLE:
            return { [errorsTypes.THROTTLE]: true };
        default:
            if (Object.keys(customErrors).includes(errorWithResponse.response.status.toString())) {
                return { [customErrors[errorWithResponse.response.status]]: true };
            }
            handleReportError(errorWithResponse);
            return { [errorsTypes.INTERNAL_SERVER_ERROR]: true };
    }
}

export default function useErrorMessage(errors, customErrors = {}) {
    const { t: translate } = useTranslation();

    const errorMessages = {
        ...customErrors,
        [errorsTypes.INTERNAL_SERVER_ERROR]: translate(
            "Actiunea nu a putut fi finalizata intrucat a intervenit o eroare. Va rugam sa reincercati.",
        ),
        [errorsTypes.THROTTLE]: translate(
            "Actiunea nu poate fi procesata inca odata intr-un interval atat de scurt. Va rugam asteptati cateva minute si reincercati.",
        ),
        [errorsTypes.MAINTENANCE]: translate(
            "Aplicatia este in curs de actualizare. Va rugam asteptati cateva minute si reincercati.",
        ),
    };

    const k = Object.keys(errorMessages).find((k) => Object.keys(errors || {}).includes(k));

    if (!errorMessages[k]) {
        return null;
    }

    if (typeof errorMessages[k] === "string") {
        return errorMessages[k];
    }

    if (!errorMessages[k][errors[k]]) {
        throw new Error("Field error was mapped but the error itself was not: " + errors[k]);
    }

    return errorMessages[k][errors[k]];
}
