import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import cc from "classcat";
import reduxStore, { thunkOptions } from "../../../providers/reduxProvider";
import ApplicationErrorHandler from "../../errors/components/ApplicationErrorHandler";
import Router from "../../router/components/Router";
import TooltipProvider from "../../uikit/components/Tooltip/TooltipProvider";
import IsMobileContext from "./IsMobileContext";
import AuthManager from "../../auth/components/AuthManager";
import FlashMessengerProvider from "../../uikit/components/FlashMessenger/FlashMessengerProvider";
import FlashMessenger from "../../uikit/components/FlashMessenger/FlashMessenger";
import ApplicationLoaderProvider from "./ApplicationLoaderContext";
import LanguageLoaderProvider from "../../intl/components/LanguageLoaderProvider";

export default function App() {
    return (
        <ErrorBoundary FallbackComponent={ApplicationErrorHandler}>
            <Provider store={reduxStore}>
                <IsMobileContext>
                    <ApplicationLoaderProvider>
                        <TooltipProvider>
                            <FlashMessengerProvider thunkOptions={thunkOptions}>
                                <HelmetProvider>
                                    <Helmet
                                        titleTemplate="%s | Premier Energy Invoices"
                                        defaultTitle="Premier Energy Invoices">
                                        <html className="h-full scroll-smooth bg-gray-100" />
                                        <body className={cc(["h-full"])} />
                                    </Helmet>
                                    <FlashMessenger />
                                    <LanguageLoaderProvider thunkOptions={thunkOptions}>
                                        <AuthManager>
                                            <Router />
                                        </AuthManager>
                                    </LanguageLoaderProvider>
                                </HelmetProvider>
                            </FlashMessengerProvider>
                        </TooltipProvider>
                    </ApplicationLoaderProvider>
                </IsMobileContext>
            </Provider>
        </ErrorBoundary>
        // </React.StrictMode>
    );
}
