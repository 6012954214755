import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useRouteError } from "react-router-dom";
import PrivateLayout from "../../app/components/Layout/PrivateLayout";
import PublicLayout from "../../app/components/Layout/PublicLayout";
import { authSelectors } from "../../../redux/auth";
import PrivatePage from "../../app/components/Page/PrivatePage";
import routes from "../../router/utils/routes";
import NotFoundErrorPage from "./NotFoundErrorPage";
import handleReportError from "../helpers/handleReportError";
import MaintenanceErrorPage from "./MaintenanceErrorPage";
import InternalServerErrorPage from "./InternalServerErrorPage";
import useNavigate from "../../router/utils/useNavigate";

RouterErrorHandler.propTypes = {};

export default function RouterErrorHandler() {
    const error = useRouteError();
    handleReportError(error);

    /**
     * --------------------
     * Get the identity in order to ensure we can link to the dashboard or login
     * --------------------
     */
    const hasIdentity = useSelector(authSelectors.hasIdentity);
    const hasIdentityRef = useRef(hasIdentity);
    const navigate = useNavigate();

    useEffect(() => {
        if (hasIdentityRef.current !== hasIdentity) {
            if (!hasIdentity) {
                navigate(routes.AUTH_LOGIN);
            }
        }
    }, [hasIdentity]);

    /**
     * --------------------
     * Get the translator for the page title
     * --------------------
     */
    const { t } = useTranslation();

    let isMaintenanceError = false;
    let isNotFoundError = false;

    if (error.status === 503 || (error.response && error.response.status === 503)) {
        isMaintenanceError = true;
    }

    if (error.status === 404 || (error.response && error.response.status === 404)) {
        isNotFoundError = true;
    }

    const Layout = hasIdentity ? PrivateLayout : PublicLayout;

    return (
        <Layout>
            <PrivatePage title={isMaintenanceError ? t("Maintenance") : t("Internal Server Error")}>
                {isMaintenanceError && <MaintenanceErrorPage />}
                {isNotFoundError && <NotFoundErrorPage />}
                {isNotFoundError && <InternalServerErrorPage />}
            </PrivatePage>
        </Layout>
    );
}
