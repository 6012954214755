import React from "react";
import cc from "classcat";
import PropTypes from "prop-types";

InputError.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default function InputError({ children, className, ...props }) {
    return (
        <p className={cc([className, "mt-2 text-sm text-red-600"])} {...props}>
            {children}
        </p>
    );
}
