import React, { useEffect, useState } from "react";
import { Root as ToasterRoot, Description as ToasterDescription, Close as ToasterClose } from "@radix-ui/react-toast";
import useFlashMessenger from "../../../app/hooks/useFlashMessenger";
import cc from "classcat";
import { CheckCircleIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";

export default function FlashMessenger() {
    const [open, setOpen] = useState(false);
    const { message, removeMessage } = useFlashMessenger();

    const animationDuration = 300;

    function onOpenChange(opened) {
        setOpen(opened);
        // We want the animation to finish before removing the message
        setTimeout(() => {
            removeMessage();
        }, animationDuration);
    }

    useEffect(() => {
        setOpen(!!message);
    }, [message]);

    return (
        message && (
            <ToasterRoot
                open={open}
                onOpenChange={(opened) => onOpenChange(opened)}
                duration={message.duration}
                // If we want to use a variable to set the value for tailwind animation duration outside of
                // tailwind.config we have to use style property with variable name from tailwind.config
                style={{ "--animationDuration": `${animationDuration}ms` }}
                className={twMerge(
                    cc([
                        "data-[swipe=move]:translate-x-toasterMove data-[state=closed]:animate-toasterOut data-[state=open]:animate-toasterIn data-[swipe=end]:animate-toasterSwipeOut flex justify-between rounded-md border-l-8 bg-white px-3 py-4 shadow-around data-[swipe=cancel]:translate-x-0",
                        {
                            "border-red-500 text-red-600": message?.type === "error",
                            "border-green-500 text-green-500": message?.type === "success",
                        },
                    ]),
                )}>
                <ToasterDescription>
                    {message && (
                        <div className="flex items-center gap-x-4">
                            <div>
                                {message.type === "error" && <XCircleIcon className="h-9 w-9" />}
                                {message.type === "success" && <CheckCircleIcon className="h-9 w-9" />}
                            </div>
                            <div className="font-semibold text-stone-900">{message.message}</div>
                        </div>
                    )}
                </ToasterDescription>
                <ToasterClose>
                    <XMarkIcon className="h-9 w-9 text-gray-400" />
                </ToasterClose>
            </ToasterRoot>
        )
    );
}
