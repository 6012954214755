import { useState } from "react";

export default function useFormikHandleChange(formik) {
    const [changed, setChanged] = useState({});

    function onChange(e) {
        formik.handleChange(e);
        if (!changed[e.target.name]) {
            setChanged((data) => ({ ...data, [e.target.name]: true }));
        }
    }

    function onSubmit(e) {
        const changed = {};
        Object.keys(formik.values).forEach((key) => {
            changed[key] = true;
            setChanged(changed);
        });
        formik.handleSubmit(e);
    }

    return [changed, onChange, onSubmit];
}
