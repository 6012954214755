import React, { useContext } from "react";
import { I18nContext } from "react-i18next";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import RoutesContextProvider from "../utils/RoutesContextProvider";
import NotFoundErrorPage from "../../errors/components/NotFoundErrorPage";
import RouterErrorHandler from "../../errors/components/RouterErrorHandler";
import PublicLayout from "../../app/components/Layout/PublicLayout";
import routes, { routesParts } from "../utils/routes";
import Navigate from "../utils/Navigate";
import useCreatePublicLazyLoader from "../../app/hooks/useCreatePublicLazyLoader";
import useCreatePrivateLazyLoader from "../../app/hooks/useCreatePrivateLazyLoader";
import PrivateLayout from "../../app/components/Layout/PrivateLayout";

export default function Router() {
    const { i18n } = useContext(I18nContext);

    /**
     * These 3 (lazy)loader creator functions ensure that:
     * 1) if there is an error already in the loaders chain, they do not continue
     * 2) if the user is / is not authenticated, they redirect to the proper location (login/dash)
     */
    const createPublicLazyLoader = useCreatePublicLazyLoader(i18n);
    const createPrivateLazyLoader = useCreatePrivateLazyLoader(i18n);

    return (
        <RoutesContextProvider routes={routes}>
            <RouterProvider
                router={createBrowserRouter(
                    createRoutesFromElements(
                        <Route ErrorBoundary={RouterErrorHandler}>
                            <Route
                                path=".well-known/change-password"
                                element={() => <Navigate to={routesParts.AUTH_FORGOT_PASSWORD} />}
                            />
                            <Route element={<PrivateLayout />}>
                                <Route
                                    path={routesParts.INVOICES}
                                    lazy={createPrivateLazyLoader(
                                        () => import("../../invoices/pages/ListInvoicesPage"),
                                    )}></Route>

                                <Route
                                    path={routesParts.ACTIVATE_2FA}
                                    lazy={createPrivateLazyLoader(
                                        () => import("../../myAccount/pages/ActivateTwoFactorAuthPage"),
                                    )}
                                />

                                <Route
                                    path={routesParts.MY_ACCOUNT}
                                    lazy={createPrivateLazyLoader(() => import("../../myAccount/pages/MyAccountPage"))}>
                                    <Route
                                        path={routesParts.MY_ACCOUNT_CHANGE_PASSWORD}
                                        lazy={createPrivateLazyLoader(
                                            () => import("../../myAccount/pages/ChangePasswordPage"),
                                        )}
                                    />
                                    <Route
                                        path={routesParts.MY_ACCOUNT_VERIFY_EMAIL}
                                        lazy={createPrivateLazyLoader(
                                            () => import("../../myAccount/components/VerifyEmailModal"),
                                        )}
                                    />
                                </Route>
                            </Route>

                            <Route element={<PublicLayout />}>
                                <Route path={routesParts.ROOT} element={<Navigate to={routesParts.AUTH_LOGIN} />} />

                                <Route
                                    path={routesParts.AUTH_LOGIN}
                                    lazy={createPublicLazyLoader(() => import("../../auth/pages/LoginPage"))}
                                />
                                <Route
                                    path={routesParts.AUTH_LOGIN_2FA}
                                    lazy={createPublicLazyLoader(() => import("../../auth/pages/Login2faPage"))}
                                />
                                <Route
                                    path={routesParts.AUTH_FORGOT_PASSWORD}
                                    lazy={createPublicLazyLoader(
                                        () => import("../../auth/pages/StartForgotPasswordPage"),
                                    )}
                                />
                                <Route
                                    path={routesParts.AUTH_FORGOT_PASSWORD_FINISH}
                                    lazy={createPublicLazyLoader(
                                        () => import("../../auth/pages/FinishForgotPasswordPage"),
                                    )}
                                />
                                <Route
                                    path={routesParts.AUTH_SET_PASSWORD}
                                    lazy={createPublicLazyLoader(
                                        () => import("../../auth/pages/FinishForgotPasswordPage"),
                                    )}
                                />
                                <Route
                                    path={routesParts.AUTH_SET_2FA}
                                    lazy={createPublicLazyLoader(
                                        () => import("../../myAccount/pages/ActivateTwoFactorAuthPage"),
                                    )}
                                />
                            </Route>

                            <Route path="*" element={<NotFoundErrorPage />} />
                        </Route>,
                    ),
                )}
            />
        </RoutesContextProvider>
    );
}
