import React from "react";
import { Close as DialogPrimitiveClose } from "@radix-ui/react-dialog";
import PropTypes from "prop-types";
import cc from "classcat";
import { twMerge } from "tailwind-merge";

ModalClose.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default function ModalClose({ children, className }) {
    return (
        <>
            <DialogPrimitiveClose
                asChild
                className={twMerge(
                    cc([
                        "absolute right-6 top-3 z-10 self-end px-0 py-0 text-gray-400 hover:cursor-pointer",
                        className,
                    ]),
                )}>
                {children}
            </DialogPrimitiveClose>
        </>
    );
}
