import { useStore } from "react-redux";
import { createElement } from "react";
import { generatePath } from "react-router";
import { authSelectors } from "../../../redux/auth";
import { redirect, matchPath } from "react-router-dom";
import routes from "../../router/utils/routes";
import { thunkOptions } from "../../../providers/reduxProvider";

export default function useCreatePrivateLazyLoader(i18n) {
    const store = useStore();

    return function createPrivateLazyLoader(lazyComponentLoader) {
        return async function () {
            const ref = {
                current: null,
            };

            return {
                Component: (props) => createElement(ref.current, props),
                loader: async (opts) => {
                    if (opts.request.signal.aborted) {
                        return null;
                    }

                    /*
                     * we show the toaster (no matter if he was logged in and
                     * the session expired or just entered a valid url while logged out)
                     */
                    if (!authSelectors.hasIdentity(store.getState())) {
                        /*
                         * We don't want to show the toaster message if the user goes on the root path
                         */
                        if (!matchPath(routes.ROOT, document.location.pathname)) {
                            thunkOptions.setErrorMessage({
                                message: i18n.t("Sesiunea a expirat."),
                            });
                        }

                        throw redirect(generatePath(routes.AUTH_LOGIN, opts.params));
                    }

                    /*
                     * we want to force activate 2fa if not active
                     */
                    if (authSelectors.shouldEnableTwoFactorAuth(store.getState())) {
                        if (!matchPath(routes.ACTIVATE_2FA, document.location.pathname)) {
                            // throw redirect(generatePath(routes.ACTIVATE_2FA, opts.params));
                            // TODO: redirect to 2fa activate page
                        }
                    }

                    let loaderResult = null;
                    const result = await lazyComponentLoader();
                    if (result.loader) {
                        loaderResult = await result.loader(opts, {
                            i18n,
                            dispatch: store.dispatch,
                            getState: store.getState,
                        });
                    }

                    ref.current = result.default;
                    return loaderResult;
                },
            };
        };
    };
}
