import { stringify } from "qs";
import { configSelectors } from "./config";

function requestAction(options) {
    return (dispatch, getState, { httpClient }) => {
        const host = configSelectors.apiHost(getState());
        if (options.method.toLowerCase() === "get" && options.data && Object.keys(options.data).length > 0) {
            options.url += "?" + stringify(options.data);
            delete options.data;
        }

        options = {
            ...options,
            url: `${host}${options.url}`,
            headers: { ...options.headers },
        };

        return httpClient.request(options).then((response) => (options.raw ? response : response.data));
    };
}

export function callPublicApiAction(method, url, data = {}, options = {}) {
    return requestAction({
        url,
        method,
        data,
        ...options,
    });
}

export function callPrivateApiAction(method, url, data = {}, options = {}) {
    return (dispatch) => {
        return dispatch(
            requestAction({
                url,
                method,
                data,
                ...options,
                headers: {
                    ...options.headers,
                },
            }),
        );
    };
}
