import React from "react";
import { Root as LabelPrimitiveRoot } from "@radix-ui/react-label";
import PropTypes from "prop-types";
import cc from "classcat";
import { twMerge } from "tailwind-merge";

Label.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default function Label({ children, className, ...props }) {
    return (
        <LabelPrimitiveRoot className={twMerge(cc(["text-sm font-semibold dark:text-gray-200", className]))} {...props}>
            {children}
        </LabelPrimitiveRoot>
    );
}
