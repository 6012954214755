import React from "react";
import { Title as DialogPrimitiveTitle } from "@radix-ui/react-dialog";
import PropTypes from "prop-types";
import cc from "classcat";
import { Helmet } from "react-helmet-async";
import { twMerge } from "tailwind-merge";

ModalTitle.propTypes = {
    children: PropTypes.string.isRequired,
    align: PropTypes.oneOf(["center", "start"]),
};

export default function ModalTitle({ children }) {
    return (
        <>
            <Helmet>
                <title>{children}</title>
            </Helmet>
            <DialogPrimitiveTitle
                className={twMerge(
                    cc([
                        "w-full pb-2 text-2xl font-semibold text-black dark:border-gray-700 dark:text-gray-200 flex items-center justify-center text-center md:pt-6",
                    ]),
                )}>
                {children}
            </DialogPrimitiveTitle>
        </>
    );
}
