import PropTypes from "prop-types";
import React from "react";
import cc from "classcat";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Slot } from "@radix-ui/react-slot";
import { twMerge } from "tailwind-merge";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

Alert.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(["danger", "success"]).isRequired,
    title: PropTypes.node,
    icon: PropTypes.node,
    onDismiss: PropTypes.func,
};

export default function Alert({ children, variant, className, title, icon, onDismiss, ...props }) {
    return (
        <div
            className={twMerge(
                cc([
                    "relative rounded-md border-2 px-3 py-4 text-base",
                    {
                        "border-green-500  bg-green-50 text-green-700 dark:bg-green-500 dark:text-gray-200":
                            variant === "success",
                    },
                    {
                        "border-red-500  bg-red-50 text-red-600 dark:bg-red-500 dark:text-gray-200":
                            variant === "danger",
                    },
                    {
                        "py-2": title,
                    },
                    className,
                ]),
            )}
            role="alert"
            {...props}>
            <div className={cc([{ "flex items-center": !title }, "gap-x-2 pr-5"])}>
                <div className="flex items-center gap-x-2">
                    {variant === "danger" && (
                        <Slot className={cc(["h-10 w-10 flex-shrink-0 fill-red-500 dark:fill-gray-200"])}>
                            {variant === "danger" && <ExclamationTriangleIcon className="h-8 w-8" />}
                        </Slot>
                    )}
                    {variant === "success" && (
                        <Slot className={cc(["h-10 w-10 flex-shrink-0 fill-green-500 dark:fill-gray-200"])}>
                            {variant === "success" && <CheckCircleIcon className="h-8 w-8" />}
                        </Slot>
                    )}
                    {title && <div className="mb-1 flex items-center justify-between font-bold">{title}</div>}
                    {onDismiss && (
                        <button
                            type="button"
                            onClick={onDismiss}
                            className="absolute right-0 top-0 inline-flex rounded-md p-1 text-gray-400 focus:outline-none focus:ring-2">
                            <XMarkIcon className="h-7 w-7" />
                        </button>
                    )}
                </div>
                <div className="flex-grow">
                    <div className="flex flex-1 items-center">{children}</div>
                </div>
            </div>
        </div>
    );
}
