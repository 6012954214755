import React from "react";
import { Description as DialogPrimitiveDescription } from "@radix-ui/react-dialog";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import cc from "classcat";

ModalContent.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default function ModalContent({ children, className }) {
    return (
        <DialogPrimitiveDescription asChild>
            <div className={twMerge(cc(["my-7", className]))}>{children}</div>
        </DialogPrimitiveDescription>
    );
}
