import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import cc from "classcat";
import { twMerge } from "tailwind-merge";
import Spinner from "./Spinner";

const Button = forwardRef(function RefButton(
    {
        children,
        className,
        variant = "primary",
        block = false,
        pilled = false,
        disabled = false,
        loading = false,
        iconRight = null,
        iconLeft = null,
        type = "button",
        inFilterGrid,
        ...props
    },
    ref,
) {
    if (loading) {
        disabled = true;
    }
    const combinedCc = twMerge(
        cc([
            "flex rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm",
            {
                "bg-indigo-600 text-white": variant === "primary" && !disabled,
                "bg-white text-gray-900": variant === "secondary" && !disabled,
                "bg-indigo-50 text-indigo-600": variant === "soft" && !disabled,
                "bg-red-600 text-red-50": variant === "danger" && !disabled,
            },
            {
                "hover:bg-indigo-500": variant === "primary" && !disabled,
                "hover:bg-gray-50": variant === "secondary" && !disabled,
                "hover:bg-indigo-100": variant === "soft" && !disabled,
                "hover:bg-red-500 hover:text-red-100": variant === "danger" && !disabled,
            },
            {
                "bg-indigo-400 text-white": variant === "primary" && disabled,
                "bg-gray-100 text-gray-600": variant === "secondary" && disabled,
                "bg-indigo-100 text-indigo-400": variant === "soft" && disabled,
                "bg-red-100 text-red-400": variant === "danger" && disabled,
            },
            { "ring-1 ring-inset ring-gray-300": variant === "secondary" },
            { "inline-flex justify-center items-center": variant === "primary" || variant === "secondary" },
            {
                "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600":
                    variant === "primary",
            },
            {
                "rounded-full": pilled,
            },
            {
                "w-full": block,
            },
            {
                "cursor-not-allowed ": disabled,
            },
            {
                "opacity-50": disabled,
            },
            {
                "pl-3.5 pr-6": iconLeft,
            },
            {
                "mb-1": inFilterGrid,
            },
            className,
        ]),
    );

    if (typeof children === "function") {
        return children(
            {
                className: combinedCc,
                disabled,
            },
            {
                IconRight: iconRight,
                Loader: loading && <Spinner />,
            },
        );
    }

    return (
        <button ref={ref} className={combinedCc} disabled={disabled} type={type} {...props}>
            {iconLeft}
            {loading && <Spinner size="small" variant={variant} />}
            {children}
            {iconRight}
        </button>
    );
});

Button.propTypes = {
    variant: PropTypes.oneOf(["primary", "secondary", "soft", "danger"]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    pilled: PropTypes.bool,
    loading: PropTypes.bool,
    type: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.func.isRequired]),
    iconRight: PropTypes.node,
    iconLeft: PropTypes.node,
    inFilterGrid: PropTypes.bool,
};

export default Button;
