/**
 * !!!!!!!!
 * IMPORTANT: DO NOT FORGET TO ADD / REMOVE / EDIT ROUTES HERE IN BOTH PLACES
 * !!!!!!!!
 */

export const routesParts = {
    // Auth routes
    AUTH_LOGIN: "login",
    AUTH_LOGIN_2FA: "login/two-factor",
    AUTH_LOGIN_VERIFY_EMAIL: "login/verify-email",
    AUTH_SET_2FA: "setup-two-factor",
    AUTH_SET_PASSWORD: "set-password",
    AUTH_FORGOT_PASSWORD: "forgot-password",
    AUTH_FORGOT_PASSWORD_FINISH: "forgot-password/finish",
    //routes for the premier-energy application
    ROOT: "",
    INVOICES: "invoices",
    ACTIVATE_2FA: "2fa/activate",
    // routes for my account
    MY_ACCOUNT: "my-account",
    MY_ACCOUNT_CHANGE_PASSWORD: "change-password",
    MY_ACCOUNT_VERIFY_EMAIL: "verify-email",
};

const routes = {
    // Auth routes
    AUTH_LOGIN: "/login",
    AUTH_LOGIN_2FA: "/login/two-factor",
    AUTH_SET_2FA: "/setup-two-factor",
    AUTH_LOGIN_VERIFY_EMAIL: "/login/verify-email",
    AUTH_SET_PASSWORD: "/set-password",
    AUTH_FORGOT_PASSWORD: "/forgot-password",
    AUTH_FORGOT_PASSWORD_FINISH: "/forgot-password/finish",
    //routes for the premier-energy application
    ROOT: "/",
    INVOICES: "/invoices",
    ACTIVATE_2FA: "/2fa/activate",
    // routes for my account
    MY_ACCOUNT: "/my-account",
    MY_ACCOUNT_CHANGE_PASSWORD: "/my-account/change-password",
    MY_ACCOUNT_VERIFY_EMAIL: "/my-account/verify-email",
};

export default routes;
