import axios, { CanceledError } from "axios";
import axiosRetry from "axios-retry";
import { authActions, authSelectors } from "../redux/auth";
import { configSelectors } from "../redux/config";

httpClientProvider.store = null;

let tries = 0;
let csrsfPromise;
export default function httpClientProvider(store) {
    /**
     * Create the main HTTP Client
     */
    const httpClient = axios.create({
        withCredentials: true,
        withXSRFToken: (config) => config.url.includes(configSelectors.apiHost(store.getState())),
    });

    axiosRetry(httpClient, {
        retries: 3, // Retry once
        retryDelay: axiosRetry.exponentialDelay,
        retryCondition: (error) => !(error instanceof CanceledError) && typeof error.response === "undefined",
    });

    /**
     * Ensure that when an error occurs, if it's a 401 error, we set identity to null so the use effect
     *  from privateLayout will redirect to login and we show the toaster (here to avoid showing it after logout)
     */
    httpClient.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (!error.response) {
                return Promise.reject(error);
            }

            /**
             * If a request is made and fails with 401 and the user is logged in (meaning he made a request either by navigating
             * OR by submitting a form or just a custom API call...) then show the toaster expiration message + clear identity.
             * Since the user was logged in, the Private Layout will redirect the user to the login page.
             */
            if (error.response.status === 401 && authSelectors.hasIdentity(store.getState())) {
                store.dispatch((dispatch, getState, thunkOptions) => {
                    thunkOptions.setErrorMessage({
                        message: thunkOptions.i18n.t("Sesiunea a expirat."),
                    });
                });
                store.dispatch(authActions.setIdentity(null));
            }

            /*
             * If one or more requests fail with CSRF error, pause them all,
             * make ONE request to get a new CSRF, then retry all requests.
             *
             * If the retried request still fails with 419, do not retry again
             * and just hard fail
             */
            if (error.response.status === 419) {
                if (tries >= 1) {
                    return Promise.reject(error);
                }

                if (!csrsfPromise) {
                    tries++;
                    csrsfPromise = store.dispatch(authActions.refreshCsrfToken());
                }

                return csrsfPromise
                    .then(() => httpClient.request(error.config))
                    .then((response) => {
                        tries = 0;
                        return response;
                    })
                    .catch((error) => {
                        csrsfPromise = null;
                        throw error;
                    });
            }

            return Promise.reject(error);
        },
    );

    return httpClient;
}
