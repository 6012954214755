import { createReducer } from "@reduxjs/toolkit";
import { formatISO } from "date-fns";
import { memoize } from "proxy-memoize";
import { TWO_FACTOR_AUTH_TYPES } from "../features/auth/utils/twoFactorAuthTypes";
import { callPrivateApiAction, callPublicApiAction } from "./api";
import handleReportError from "../features/errors/helpers/handleReportError";

/*******************
 * ACTION TYPES
 *******************/
const actionTypes = {
    SET_IDENTITY: "auth/SET_IDENTITY",
    UPDATE_EMAIL: "auth/UPDATE_EMAIL",
};

/*******************
 * REDUCER
 *******************/
export default createReducer({ identity: null }, (builder) => {
    builder.addCase(actionTypes.SET_IDENTITY, (state, action) => {
        return { ...state, identity: action.payload };
    });
    builder.addCase(actionTypes.UPDATE_EMAIL, (state, action) => {
        return { ...state, identity: { ...state.identity, email: action.payload } };
    });
});

/*******************
 * ACTIONS
 *******************/
export const authActions = {
    loginApi({ email, password, rememberMe }, abortControllerSignal) {
        const ua = navigator.userAgent;
        const tabletRegex = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;
        const mobRegex =
            /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/;

        let deviceType;
        if (tabletRegex.test(ua)) deviceType = "tablet";
        if (mobRegex.test(ua)) deviceType = "mobile";
        if (!deviceType) deviceType = "desktop";

        return callPublicApiAction(
            "POST",
            "/v1/users/login",
            {
                email,
                password,
                rememberMe,
                deviceName: navigator.userAgent,
                deviceType: deviceType,
            },
            { signal: abortControllerSignal, raw: true },
        );
    },

    loginTwoFactorAuthApi({ code, rememberDevice }, abortControllerSignal) {
        return callPublicApiAction(
            "POST",
            "/v1/users/login/2fa",
            {
                code,
                rememberDevice,
            },
            { signal: abortControllerSignal },
        );
    },

    sendTwoFactorAuthCodeApi(type, abortControllerSignal) {
        return callPublicApiAction("POST", "/v1/users/2fa/send", { type }, { signal: abortControllerSignal });
    },

    startStepUpTwoFactorAuthApi({ type, email }, abortControllerSignal) {
        return callPublicApiAction(
            "POST",
            "/v1/users/2fa/step-up/start",
            { type, email },
            { signal: abortControllerSignal },
        );
    },

    finishStepUpTwoFactorAuthApi({ code, type }, abortControllerSignal) {
        return callPublicApiAction(
            "POST",
            "/v1/users/2fa/step-up/finish",
            { type, code },
            { signal: abortControllerSignal },
        );
    },

    /**
     * Used by the login & signup flows but also partially by the getIdentify flow
     */
    setIdentity(payload) {
        return (dispatch) => {
            dispatch({
                type: actionTypes.SET_IDENTITY,
                payload: payload,
            });
        };
    },

    getIdentity() {
        return async (dispatch) => {
            const { body } = await dispatch(callPublicApiAction("GET", "/v1/users/me/identity"));
            dispatch(this.setIdentity(body));

            return body;
        };
    },

    logoutApi: function () {
        return (dispatch) => {
            try {
                dispatch(callPrivateApiAction("POST", "/v1/users/me/logout"));
                dispatch(this.setIdentity(null));
            } catch (error) {
                handleReportError(error);
            }
        };
    },

    startForgotPasswordApi(email, abortControllerSignal) {
        return callPublicApiAction(
            "POST",
            "/v1/users/reset-password/start",
            {
                email,
            },
            { signal: abortControllerSignal },
        );
    },

    changePasswordApi(values, abortControllerSignal) {
        return callPublicApiAction("POST", "/v1/users/change-password", values, {
            signal: abortControllerSignal,
            raw: true,
        });
    },

    updateMyAccountApi({ fname, lname, email }, abortControllerSignal) {
        return async (dispatch, getState) => {
            const result = await dispatch(
                callPrivateApiAction(
                    "POST",
                    "/v1/users/me/account",
                    {
                        fname,
                        lname,
                        email,
                    },
                    { signal: abortControllerSignal },
                ),
            );

            const identity = getIdentity(getState());
            const changedEmail = identity.email !== email;

            dispatch({
                type: actionTypes.SET_IDENTITY,
                payload: { ...identity, fname, lname, email, isEmailVerified: !changedEmail },
            });

            return result;
        };
    },

    startActivateTwoFactorAuth({ method, phoneNumber = null }, abortControllerSignal) {
        return async (dispatch) => {
            return await dispatch(
                callPrivateApiAction(
                    "POST",
                    "/v1/users/2fa/activate/start",
                    {
                        method,
                        phoneNumber,
                    },
                    { signal: abortControllerSignal },
                ),
            );
        };
    },

    finishActivateTwoFactorAuth({ method, code, phoneNumber }, abortControllerSignal) {
        return async (dispatch, getState) => {
            const response = (
                await dispatch(
                    callPrivateApiAction(
                        "POST",
                        "/v1/users/2fa/activate/finish",
                        {
                            method,
                            code,
                            phoneNumber,
                        },
                        { signal: abortControllerSignal },
                    ),
                )
            ).body;

            if (method === TWO_FACTOR_AUTH_TYPES.SMS) {
                phoneNumber =
                    phoneNumber[0] + phoneNumber[1] + "*".repeat(phoneNumber.length - 3) + phoneNumber.slice(-2);
            }

            if (hasIdentity(getState())) {
                dispatch({
                    type: actionTypes.SET_IDENTITY,
                    payload: {
                        ...getIdentity(getState()),
                        twoFactorAuthMethod: {
                            type: method,
                            phoneNumber: phoneNumber,
                            createdAt: formatISO(new Date(), "YYYY-MM-DD"),
                            updatedAt: formatISO(new Date(), "YYYY-MM-DD"),
                        },
                    },
                });
            }

            return response;
        };
    },

    regenerateTwoFactorAuthCodes(abortControllerSignal) {
        return async (dispatch) => {
            return (
                await dispatch(
                    callPrivateApiAction(
                        "POST",
                        "/v1/users/me/2fa/regenerate-recovery-codes",
                        {},
                        { signal: abortControllerSignal },
                    ),
                )
            ).body.recoveryCodes;
        };
    },

    finishDeactivateTwoFactorAuth(code, abortControllerSignal) {
        return async (dispatch, getState) => {
            await dispatch(
                callPrivateApiAction(
                    "POST",
                    "/v1/users/me/2fa/deactivate/finish",
                    {
                        code,
                    },
                    { signal: abortControllerSignal },
                ),
            );

            dispatch({
                type: actionTypes.SET_IDENTITY,
                payload: {
                    ...getIdentity(getState()),
                    twoFactorAuthMethod: null,
                },
            });
        };
    },

    resendVerifyEmail(abortControllerSignal) {
        return async (dispatch) => {
            await dispatch(
                callPrivateApiAction("POST", "/v1/users/me/verify-email/resend", {}, { signal: abortControllerSignal }),
            );
        };
    },

    finishVerifyEmail(code, abortControllerSignal) {
        return async (dispatch, getState) => {
            await dispatch(
                callPrivateApiAction(
                    "POST",
                    "/v1/users/me/verify-email/finish",
                    { code },
                    { signal: abortControllerSignal },
                ),
            );

            dispatch({
                type: actionTypes.SET_IDENTITY,
                payload: { ...getIdentity(getState()), isEmailVerified: true },
            });
        };
    },

    refreshCsrfToken(abortControllerSignal) {
        return async (dispatch) => {
            await dispatch(callPrivateApiAction("GET", "/v1/auth/csrf", { signal: abortControllerSignal }));
        };
    },
};

/*******************
 * SELECTORS
 *******************/
const getIdentity = memoize((state) => state.auth.identity);
const hasIdentity = memoize((state) => !!getIdentity(state));

export const authSelectors = {
    hasIdentity,
    shouldEnableTwoFactorAuth: memoize((state) => !getIdentity(state).twoFactorAuthMethod),
    twoFactorAuthMethod: memoize((state) => getIdentity(state).twoFactorAuthMethod?.type),
    twoFactorAuthPhoneNumber: memoize((state) => getIdentity(state)?.twoFactorAuthMethod?.phoneNumber),
    fname: memoize((state) => getIdentity(state).fname),
    lname: memoize((state) => getIdentity(state).lname),
    email: memoize((state) => getIdentity(state).email),
    emailIsVerified: memoize((state) => getIdentity(state).isEmailVerified),
    preferredLocale: memoize((state) => getIdentity(state).locale),
};
