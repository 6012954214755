import React from "react";
import Trans from "../../intl/components/Trans";

MaintenanceErrorPage.propTypes = {};

/**
 * This page is rendered inside RouterErrorHandler with public/private layout or
 * directly by ApplicationErrorHandler without layout if getIdentity responded with 503
 */
export default function MaintenanceErrorPage() {
    return (
        <div className="px-4 py-10 text-center sm:px-6 lg:px-8">
            <h1 className="block text-7xl font-bold text-black dark:text-gray-50 sm:text-7xl">
                <Trans>Mentenanta</Trans>
            </h1>
            <p className="mt-3 text-gray-600 dark:text-gray-400">
                <Trans>Te rugăm încearcă mai târziu.</Trans>
            </p>
        </div>
    );
}
