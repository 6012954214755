import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import cc from "classcat";
import { twMerge } from "tailwind-merge";
import Label from "../Label";
import InputError from "./InputError";
import Spinner from "../Spinner";

const Input = forwardRef(function Input(
    {
        id = null,
        name,
        label,
        className,
        disabled = false,
        errorMessage,
        inlineButtonRight,
        rows = null,
        invalid,
        isLoading,
        helperText,
        onFocus: _onFocus,
        onBlur: _onBlur,
        LabelClassName,
        textArea,
        inlineAddonRight,
        tooltipContent,
        ...props
    },
    ref,
) {
    function onFocus(e) {
        _onFocus && _onFocus(e);
    }

    function onBlur(e) {
        _onBlur && _onBlur(e);
    }

    if (!id) {
        id = name;
    }

    return (
        <div className={cc(["flex flex-col", { "gap-y-1": label }])}>
            <div className="flex items-center gap-x-2">
                {label && (
                    <Label
                        className={twMerge(cc(["block text-sm font-medium leading-6 text-gray-900", LabelClassName]))}
                        htmlFor={name}>
                        {label}
                    </Label>
                )}
            </div>
            <div
                className={twMerge(
                    cc([
                        {
                            "mt-2": label,
                            "shadow-sm relative rounded-md": invalid,
                            "shadow-sm flex rounded-md": inlineButtonRight,
                        },
                    ]),
                )}>
                <div
                    className={cc([
                        {
                            "flex flex-grow items-stretch focus-within:z-10": inlineButtonRight,
                        },
                    ])}>
                    {!textArea && (
                        <div className="relative">
                            <input
                                className={twMerge(
                                    "shadow-sm block w-full border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6",
                                    cc([
                                        {
                                            "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500":
                                                invalid,
                                            "rounded-md": !inlineButtonRight,
                                            "rounded-l-md": inlineButtonRight,
                                            "p-2": !inlineAddonRight,
                                            "pr-10": inlineAddonRight,
                                        },
                                    ]),
                                    className,
                                )}
                                id={id || name}
                                name={name}
                                disabled={disabled}
                                aria-describedby={`${id || name}-error`}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                ref={ref}
                                {...props}
                            />
                            {!isLoading && inlineAddonRight && (
                                <span
                                    className={twMerge(
                                        "absolute right-0 top-1/2 flex -translate-y-1/2 items-center text-sm text-gray-500",
                                        // cc([{ "text-blue-550": isFocused }]),
                                    )}>
                                    {inlineAddonRight}
                                </span>
                            )}
                        </div>
                    )}
                    {textArea && (
                        <textarea
                            className={twMerge(
                                "shadow-sm block w-full border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6",
                                cc([
                                    {
                                        "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500 ":
                                            invalid,
                                        "rounded-md": !inlineButtonRight,
                                        "rounded-l-md": inlineButtonRight,
                                    },
                                ]),
                                className,
                            )}
                            id={id || name}
                            name={name}
                            disabled={disabled}
                            aria-describedby={`${id || name}-error`}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            rows={rows}
                            ref={ref}
                            {...props}
                        />
                    )}

                    {isLoading && (
                        <div className="pointer-events-none absolute right-0 top-0 flex -translate-y-1/2 items-center pr-2">
                            <Spinner variant="primary" />
                        </div>
                    )}
                    {!isLoading && invalid && !textArea && (
                        <div
                            className={twMerge(
                                cc([
                                    "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3",
                                    {
                                        "pr-16": inlineButtonRight,
                                    },
                                ]),
                            )}></div>
                    )}
                </div>
                {!isLoading && inlineButtonRight && (
                    <button
                        type="button"
                        className={twMerge(
                            cc([
                                "relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                                {
                                    "ring-red-300": invalid,
                                },
                            ]),
                        )}>
                        {inlineButtonRight}
                    </button>
                )}
            </div>

            <div className="flex items-center gap-x-2">
                {helperText && !invalid && <div className="mt-2 text-sm text-gray-500">{helperText}</div>}
                {invalid && errorMessage && <InputError>{errorMessage}</InputError>}
            </div>
        </div>
    );
});

Input.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    invalid: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.node,
    errorMessage: PropTypes.node,
    inlineButtonRight: PropTypes.node,
    isLoading: PropTypes.bool,
    textArea: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    rows: PropTypes.string,
    helperText: PropTypes.node,
    LabelClassName: PropTypes.string,
    inlineAddonRight: PropTypes.node,
    tooltipContent: PropTypes.node,
};

export default Input;
