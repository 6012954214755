import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import cc from "classcat";
import Button from "../Button";

const FormikSubmitButton = forwardRef(function FormikSubmitButton(
    { children, formik, className, customLoading, disabled, customDisabled, ...props },
    ref,
) {
    const disabledState = customDisabled ? disabled : !formik.dirty || disabled;
    return (
        <Button
            type="submit"
            className={cc(["", className])}
            loading={formik.isSubmitting || customLoading}
            disabled={disabledState}
            {...props}
            ref={ref}>
            {children}
        </Button>
    );
});

FormikSubmitButton.propTypes = {
    children: PropTypes.node.isRequired,
    formik: PropTypes.object.isRequired,
    className: PropTypes.string,
    customLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    customDisabled: PropTypes.bool,
};

export default FormikSubmitButton;
