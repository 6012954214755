import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { authActions, authSelectors } from "../../../redux/auth";
import useErrorMessage from "../../app/hooks/useErrorMessage";
import useFlashMessenger from "../../app/hooks/useFlashMessenger";
import useFormik from "../../app/hooks/useFormik";
import Trans from "../../intl/components/Trans";
import Alert from "../../uikit/components/Alert";
import FormikSubmitButton from "../../uikit/components/FormikButtons/FormikSubmitButton";
import Input from "../../uikit/components/Input/Input";
import Modal from "../../uikit/components/Modal/Modal";
import ModalActions from "../../uikit/components/Modal/ModalActions";
import ModalContent from "../../uikit/components/Modal/ModalContent";
import ModalTitle from "../../uikit/components/Modal/ModalTitle";

export default function DeactivateTwoFactorAuthModal() {
    const [, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [state, setState] = useState({ deactivated: false, isStartingDeactivate: true, resentCode: false });
    const { setSuccessMessage } = useFlashMessenger();
    const [formik, { changed, onChange, onSubmit, abortController, handleError }] = useFormik({
        initialValues: {
            code: "",
        },
        validationSchema: yup.object().shape({
            code: yup.string().required("required"),
        }),
        onSubmit: async ({ code }) => {
            await dispatch(authActions.finishDeactivateTwoFactorAuth(code, abortController.signal));
            setSuccessMessage({ message: <Trans>Autentificarea in 2 pasi a fost dezactivata</Trans> });
            setState((state) => ({ ...state, deactivated: true }));
        },
    });
    const topError = useErrorMessage(formik.errors);
    let topMessage;
    if (topError) {
        topMessage = <Alert variant="danger">{topError}</Alert>;
    } else if (state.resentCode) {
        topMessage = (
            <Alert variant="success">
                <Trans>Codul a fost retrimis</Trans>
            </Alert>
        );
    }

    const phoneNumber = useSelector(authSelectors.twoFactorAuthPhoneNumber);

    function onModalFadeOut() {
        setSearchParams({});
    }

    function resendCode() {
        setState((state) => ({ ...state, isStartingDeactivate: true, resentCode: false }));
        dispatch(authActions.sendTwoFactorAuthCodeApi("deactivate-2fa", abortController.signal))
            .then(() => setState((state) => ({ ...state, resentCode: true })))
            .catch(handleError)
            .finally(() => setState((state) => ({ ...state, isStartingDeactivate: false })));
    }

    useEffect(() => {
        dispatch(authActions.sendTwoFactorAuthCodeApi("deactivate-2fa", abortController.signal))
            .then(() => setState((state) => ({ ...state, isStartingDeactivate: false })))
            .catch(handleError);
    }, []);

    return (
        <Modal open={!state.deactivated} onClose={onModalFadeOut} dismissible={!formik.isSubmitting} size="xs">
            <ModalTitle>{t("Dezactivare autentificare in 2 pasi")}</ModalTitle>
            <form onSubmit={onSubmit} noValidate>
                <ModalContent>
                    {topMessage}
                    <div className="mt-5">
                        <Input
                            label={
                                <>
                                    {phoneNumber && (
                                        <Trans>
                                            Va rugam sa introduceti codul trimis pe numarul de telefon:{" "}
                                            <strong>{{ phoneNumber }}</strong>:
                                        </Trans>
                                    )}
                                    {!phoneNumber && (
                                        <Trans>
                                            Va rugam sa introduceti codul generat de aplicatia de autentificare in 2
                                            pasi:
                                        </Trans>
                                    )}
                                </>
                            }
                            LabelClassName="font-medium"
                            name="code"
                            type="text"
                            value={formik.values.code}
                            onChange={onChange}
                            onBlur={formik.handleBlur}
                            disabled={formik.isSubmitting || state.isStartingDeactivate}
                            invalid={changed.code && formik.touched.code && !!formik.errors.code}
                            errorMessage={
                                <>
                                    {formik.errors.code === "required" && <Trans>Codul este obligatoriu</Trans>}
                                    {formik.errors.code === "invalid" && (
                                        <Trans>
                                            Codul introdus nu este corect sau a expirat. Te rugam sa reincerci.
                                        </Trans>
                                    )}
                                </>
                            }
                        />
                        {phoneNumber && (
                            <small>
                                <Trans>
                                    Nu ati primit codul?{" "}
                                    <button className="text-blue-600" type="button" onClick={resendCode}>
                                        Apasati aici pentru a retrimite
                                    </button>
                                </Trans>
                            </small>
                        )}
                    </div>
                </ModalContent>
                <ModalActions>
                    <FormikSubmitButton formik={formik} disabled={state.isStartingDeactivate}>
                        <Trans>Dezactivare</Trans>
                    </FormikSubmitButton>
                </ModalActions>
            </form>
        </Modal>
    );
}
