import { useRef, useEffect } from "react";
import { useFormik as useRealFormik } from "formik";
import { mapErrorByHttpStatusCode } from "./useErrorMessage";
import useFormikHandleChange from "./useFormikHandleChange";

export default function useFormik({ onSubmit: onClientSubmit, enableReinitialize, ...customFormik }) {
    async function onCustomSubmit(values, formikBag) {
        try {
            await onClientSubmit(values, formikBag);
        } catch (error) {
            handleError(error);
        }

        formik.setSubmitting(false);
    }

    const formik = useRealFormik({
        ...customFormik,
        onSubmit: onCustomSubmit,
        validateOnBlur: false,
        enableReinitialize: enableReinitialize ? enableReinitialize : false,
    });

    const abortControllerRef = useRef(new AbortController());

    const handleError = (err, customErrors) => {
        return formik.setErrors(mapErrorByHttpStatusCode(err, customErrors));
    };
    const [changed, onChange, onSubmit] = useFormikHandleChange(formik);

    useEffect(() => {
        return () => {
            abortControllerRef.current.abort();
            abortControllerRef.current = new AbortController();
        };
    }, []);

    return [formik, { changed, onChange, onSubmit, handleError, abortController: abortControllerRef.current }];
}
