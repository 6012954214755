import React, { createContext, useState, useEffect } from "react";
import {
    Root as DialogPrimitiveRoot,
    Content as DialogPrimitiveContent,
    Portal as DialogPrimitivePortal,
    Overlay as DialogPrimitiveOverlay,
} from "@radix-ui/react-dialog";
import PropTypes from "prop-types";
import cc from "classcat";
import ModalClose from "./ModalClose";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";

Modal.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func,
    dismissible: PropTypes.bool,
    size: PropTypes.string,
    open: PropTypes.bool,
    onOpenAutoFocus: PropTypes.func,
};

export const ModalContext = createContext(null);

export default function Modal({
    children,
    onClose: clientOnClose,
    dismissible = true,
    open = true,
    size = "sm",
    onOpenAutoFocus,
}) {
    const [fadeIn, setFadeIn] = useState(false);
    const [isOpened, setIsOpened] = useState(open);
    const fadeDuration = 200;

    function onClose() {
        if (!fadeIn || !dismissible) {
            return;
        }

        setFadeIn(false);
        setTimeout(() => {
            clientOnClose();
            setIsOpened(false);
        }, fadeDuration);
    }

    useEffect(() => {
        if (open) {
            setFadeIn(true);
        } else {
            onClose();
        }
    }, [open]);

    return (
        <ModalContext.Provider value={{ fadeIn, onClose, isOpened, fadeDuration }}>
            <DialogPrimitiveRoot open={isOpened} onOpenChange={onClose}>
                <DialogPrimitivePortal>
                    <DialogPrimitiveOverlay
                        className={cc([
                            `fixed inset-0 z-20 scale-100 overflow-y-auto bg-black/50 transition-opacity dark:bg-black/60 duration-${fadeDuration}`,
                            { "opacity-0": !fadeIn },
                            { "opacity-100": fadeIn },
                        ])}>
                        <div
                            className={twMerge(
                                cc([
                                    "pointer-events-none relative mx-auto my-0 flex min-h-screen items-center justify-center opacity-100 md:px-2 md:py-5",
                                    {
                                        "md:max-w-xl": size === "xs",
                                        "md:max-w-2xl": size === "sm",
                                        "md:max-w-4xl": size === "md",
                                        "md:max-w-7xl": size === "lg",
                                        "3xl:px-64 md:max-w-full md:px-10 lg:px-32 xl:px-40": size === "full",
                                    },
                                ]),
                            )}>
                            <DialogPrimitiveContent
                                onEscapeKeyDown={(event) => event.preventDefault()}
                                onPointerDownOutside={(event) => event.preventDefault()}
                                onOpenAutoFocus={onOpenAutoFocus}
                                className={twMerge(
                                    cc([
                                        `pointer-events-auto relative flex min-h-screen w-full flex-col items-center justify-center border bg-white px-8 py-8 opacity-100 transition-all md:min-h-fit md:rounded-md duration-${fadeDuration}`,
                                        { "top-[-100px] opacity-0": !fadeIn },
                                        { "top-0 opacity-100": fadeIn },
                                    ]),
                                )}>
                                <ModalClose>
                                    <XMarkIcon className="h-11 w-11 text-gray-400 hover:text-gray-500" />
                                </ModalClose>
                                {children}
                            </DialogPrimitiveContent>
                        </div>
                    </DialogPrimitiveOverlay>
                </DialogPrimitivePortal>
            </DialogPrimitiveRoot>
        </ModalContext.Provider>
    );
}
