import React from "react";
import PropTypes from "prop-types";
import cc from "classcat";
import { twMerge } from "tailwind-merge";

ModalActions.propTypes = {
    children: PropTypes.node,
    align: PropTypes.oneOf(["end", "center"]),
    className: PropTypes.string,
};

export default function ModalActions({ children, className }) {
    return (
        <div
            className={twMerge(
                cc([
                    "sticky bottom-0 flex w-full items-center bg-white md:relative flex-col justify-center gap-y-4",
                    className,
                ]),
            )}>
            {children}
        </div>
    );
}
